import { RootDispatch } from "./store";
import { ModelConfig, ModelEffects } from "./rematch";
import { quotation } from "../public/apis";
import { ajax } from "../public";

// 行情预警列表参数
export interface QuotationCoinSubscribeItemParams {
  key?: number; // key键
  e_symbol: string; // 行情参数
  name: string; // 行情名称
  tplId: number; // 行情ID
}

// 行情预警添加选项参数
export interface QuotationCoinSubscribeAddBoxParams {
  [key: string]: {
    name: string; // 币种名称
    listData: QuotationCoinSubscribeItemParams[];
  };
}

// 成交量列表参数
export interface QuotationCoinVolItemParams {
  key?: number; // key键
  name: string; // 成交量值
  value: string; // 成交量ID
}

// 成交量添加选项参数
export interface QuotationCoinVolAddBoxParams {
  [key: string]: {
    listData: QuotationCoinVolItemParams[];
  };
}

// 行情 state
export type QuotationModelState = {};

export const quotationModel: ModelConfig = {
  state: {},
  reducers: {},
  effects: (dispatch: RootDispatch): ModelEffects => ({
    // 行情模板列表 originFlag 0.可订阅模板 1.当前订阅模板
    async quotationCoinSubscribeList(payload: { originFlag: number }, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: quotation.quotationCoinSubscribeList,
        params: { ...payload }
      });
      return res;
    },
    // 行情模板订阅
    async quotationCoinReSubscribe(payload: QuotationCoinSubscribeItemParams[], state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        contentType: "application/json",
        url: quotation.quotationCoinReSubscribe,
        params: JSON.stringify(payload)
      });
      return res;
    },
    // 行情模板订阅
    async quotationCoinVolList(payload: { originFlag: number }, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: quotation.quotationCoinVolList,
        params: { ...payload }
      });
      return res;
    },
    // 成交量模板订阅
    async quotationCoinVolReSubscribe(payload: QuotationCoinVolItemParams[], state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        contentType: "application/json",
        url: quotation.quotationCoinVolChangeVol,
        params: JSON.stringify(payload)
      });
      return res;
    }
  })
};
