import { RootDispatch } from "./store";
import { ModelConfig, ModelEffects } from "./rematch";
import { project } from "../public/apis";
import { ajax } from "../public";

// 项目列表 参数
export interface ProjectListParams {
  recommend?: number; // 推荐状态， 0 未推荐 1推荐
  displayFlag?: number; // 展示状态 0 未展示 1展示
  search?: string; // 搜索关键字
  page?: number; // 页数
  pageSize: number; // 每页数量(默认20)
  tpypeId?: number; // 分类 用户自定义，这个分类是用户自己定的，不是
}
// 项目详细参数
export interface ProjectParams {
  id: number;
  name: string;
  brief: string;
  iconUrl: string;
  url: string;
  typeId: number;
  projectType: number;
  topOrder: number;
  createTime: number;
  recommend?: number;
  updateTime: number;
  navTypeList: [];
  content: {};
}

// 新增分类
export interface ProjectType {
  typeName: string;
  topOrder: number;
  status: number;
  id?: number; // 类型 10000.为啥是全部 1 其他
  typeId?: number; // 真实ID
  key?: number;
  createTime?: number;
  recommend?: number;
  updateTime?: number;
}

export interface ProjectTypeList {
  pageSize: number;
  recordCount: number;
  currentPage: number;
  pageCount: number;
  inforList: ProjectType[];
}

// 请求分类列表的参数
export interface TypeListParams {
  pageSize: number;
  recordCount: number;
  currentPage: number;
  pageCount: number;
  inforList: ProjectType[];
}

// export interface GetListParams {
//   page: number;
//   pageSize: number;
// }

// project state
export type ProjectModelState = {
  projectTypeList: TypeListParams;
};

export const projectModel: ModelConfig = {
  state: {
    projectTypeList: {
      pageSize: null,
      recordCount: null,
      currentPage: null,
      pageCount: null,
      inforList: []
    }
  },
  reducers: {
    projectTypeListSet: (state: ProjectModelState, payload: TypeListParams): void => {
      const listWithKey = [];
      for (const val of payload.inforList) {
        val.key = val.id;
        listWithKey.push(val);
      }
      payload.inforList = listWithKey;
      state.projectTypeList = payload;
    }
  },
  effects: (dispatch: RootDispatch): ModelEffects => ({
    // 获取项目列表
    async getprojectList(payload: ProjectListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: project.projectList,
        params: { ...payload }
      });
      return res;
    },
    //获取项目内容，编辑使用ProjectParams

    // 获取分类列表
    async projectTypeList(payload: TypeListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: project.projectTypeList,
        params: { ...payload }
      });
      if (res && res.code === 1) {
        dispatch.project.projectTypeListSet(res.obj);
      }
      return res;
    },
    async getprojectRecommendList(payload: ProjectParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectStatus,
        params: { ...payload }
      });
      return res;
    },
    async getProjectStatus(payload: ProjectParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectStatus,
        params: { ...payload }
      });
      return res;
    },
    //更新分类名称
    async projectTypeEdit(payload: ProjectType, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectTypeUpdate,
        params: { ...payload }
      });
      return res;
    },
    //新增分类
    async projectTypeAdd(payload: ProjectType, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectTypeAdd,
        params: { ...payload }
      });
      return res;
    },
    // 获取项目详情
    async getProjectDetail(paylaod: ProjectParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: project.projectDetail,
        params: { ...paylaod }
      });
      return res;
    },
    // 新增项目
    async getProjectAdd(payload: ProjectParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectAdd,
        params: { ...payload }
      });
      return res;
    },
    //更新项目
    async getProjectUpdate(payload: ProjectParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: project.projectUpdate,
        params: { ...payload }
      });
      return res;
    }
  })
};
