export const auth = {
  smsCode: `/passport/account/getverifcode`, // 获取短信验证码
  signin: `/mgr/account/editor/login` // 登录
};

export const communal = {
  urlImgUpload: `/mgr/pic/url_upload`, // url+base64图片上传
  fileImgUpload: `/mgr/picture/upload`, // 图片上传
  fileImgUpload2: `/mgr/pic/upload`, // 图片上传2
  fileLargeUpload: `/mgr/file/upload`, // 大文件分片上传
  dataOptimize: `/mgr/data/optimize` // 数据优化
};

// 专题相关接口
export const feature = {
  topicList: `/mgr/topic/list`, // 专题列表
  topicStatus: `/mgr/topic/status`, // 专题列表操作 status 0：删除， 1：撤销首页展示 撤回推荐， 2：修改推荐位权重
  topicQuerytopic: `/mgr/topic/querytopic`, // 专题详情
  topicUpdate: `/mgr/topic/update`, // 专题更新
  topicAdd: `/mgr/topic/add`, // 专题新增
  topicQuerycontent: `/mgr/topic/querycontent`, // 专题内容
  topicAddcontent: `/mgr/topic/addcontent`, // 专题内容添加
  topicUpdatecontent: `/mgr/topic/updatecontent`, // 专题内容修改
  topicDelcontent: `/mgr/topic/delcontent`, // 专题内容删除
  topicRecommendnum: `/mgr/topic/recommendnum` // 获取全部推荐权重 type4为专题推荐权重
};

// 项目库相关接口
export const project = {
  projectList: `/mgr/nav/project/list`,
  projectTypeList: `/mgr/nav/type/list/page`, // 项目库分类列表
  projectTypeAdd: `/mgr/nav/type/add`, // 项目库新增分类
  projectTypeUpdate: `/mgr/nav/type/update`, // 项目库分类属性更新
  // projectRecommend: `/mgr/nav/project/status`, //暂时用status替代recommend
  projectStatus: `/mgr/nav/project/status`, //暂时用status替代recommend
  projectAdd: `/mgr/nav/project/add`, // 新增导航项目
  projectUpdate: `/mgr/nav/project/update`, // 修改导航项目
  projectDetail: `/mgr/nav/project/one` //导航详情，按照管理应该是detail还是one呢
};

// 直播相关接口
export const live = {
  liveRoomList: `/mgr/video/live/room/list`, // 直播间列表
  liveRoomDetail: `/mgr/video/live/room/detail`, // 直播间详情
  liveRoomAdd: `/mgr/video/live/room/add`, // 新增直播间
  liveRoomUpdate: `/mgr/video/live/room/update`, // 修改直播间
  liveRoomStatus: `/mgr/video/live/room/status`, // 修改直播间状态 status: -1.删除 1.直播中 2.回放中
  liveRoomRecommend: `/mgr/video/live/room/recommend`, // 修改直播间推荐
  liveBlackUserList: `/mgr/video/live/black/user/list`, // 直播间黑名单列表
  liveBlackUserDelete: `/mgr/video/live/black/user/delete`, // 直播间黑名单删除
  liveBlackUserAdd: `/mgr/video/live/black/user/add`, // 直播间黑名单添加
  liveCommentList: `/mgr/video/live/comment/list`, // 直播间评论列表
  liveCommentDelete: `/mgr/video/live/comment/delete`, // 直播间删除评论
  liveTypeAdd: `/mgr/video/live/type/add`, // 新增直播间频道
  liveTypeUpdate: `/mgr/video/live/type/update`, // 更新直播间频道
  liveTypeList: `/mgr/video/live/type/list`, // 直播间频道列表
  liveTypeOne: `/mgr/video/live/type/one`, // 直播间频道详情
  liveTipList: `/mgr/video/live/tip/list`, // 直播间举报列表
  liveTipOne: `/mgr/video/live/tip/one`, // 直播间举报详情
  liveTipStatus: `/mgr/video/live/tip/status`, // 直播间举报状态更改
  liveCommentUserList: `/mgr/video/live/comment/user/list`, // 视频直播机器人随机列表
  liveCommentSend: `/mgr/video/live/comment/send`, // 后台发送直播评论
  liveAutoComment: `/mgr/video/live/room/auto_comment`, // 直播间自动评论开关
  liveHomeTopOrder: `/mgr/video/live/room/set/home_top_order` // 设置直播间首页推荐权重
};

// 行情相关接口
export const quotation = {
  quotationCoinSubscribeList: `/mgr/coin/subscribe/list`, // 行情模板列表 0.可订阅的全部模板 1.当前订阅模板
  quotationCoinReSubscribe: `/mgr/coin/re_subscribe`, // 行情模板订阅，成交量模板订阅
  quotationCoinVolList: `/mgr/coin/vol/list`, // 成交量模板列表 0.可订阅的全部模板 1.当前订阅模板
  quotationCoinVolChangeVol: `/mgr/coin/vol_change` // 成交量模板订阅
};
