import { RootDispatch } from "./store";
import { ModelConfig, ModelEffects } from "./rematch";
import { live } from "../public/apis";
import { ajax } from "../public";

// 主持人嘉宾列表参数
export interface LiveUserlist {
  userType?: number; // 用户类型 0.嘉宾 1.主播
  phoneNum?: number; // 用户账号
  id?: number; // ID
  type?: number; // 添加来源类型 2.后台添加
  nickName?: string; // 用户昵称
  passportId?: string; // 用户ID
  roomId?: number; // 直播间ID
  createTime?: number; // 创建时间
  updateTime?: number; // 更新时间
  content?: string; // 直播评论
  commentId?: number; // 直播评论ID 就是列表Item的id
  isBlack?: boolean; // 是否已禁言
  isBan?: boolean; // 是否黑名单
  txUserId?: string; // 腾讯ID
  blackUserFlag?: number; // 是否已禁言
  globalForbidFlag?: number; // 是否黑名单
}

// 直播间参数
export interface LiveRoomParams {
  status: number; // 直播间状态 -1.删除 0.即将开始 1.直播中 2.回放中
  roomId: number; // 直播间ID
  roomType: number; // 直播间类型 0.移动直播 1.大会直播
  guestFlag: number; // 嘉宾类型 0.单/双 人直播 1.多人直播
  liveType: number; // 分类类型 10000.全部 1.其他
  name: string; // 直播间标题
  brief: string; // 直播间简介
  beginTime: number; // 直播间开始时间 (时间戳)
  popularityAim: number; // 虚假人气
  popularityAimTime: number; // 增长时间 (分钟)
  coverPicUrl: string; // 封面图地址
  presenterList: LiveUserlist[]; // 主播账号
  guetList: LiveUserlist[]; // 邀请嘉宾列表
  pushStreamUrl: string; // 推流地址
  pullStreamUrl: string; // 拉流地址
  recommend: number; // 是否推荐 0不推荐 1推荐
  autoCommentFlag: number; // 自动评论开关 0关 1开
  topOrder: number; // 权重
  homeTopOrder: number; // 首页权重
}

// 直播间列表参数 /黑名单列表参数 / 举报列表参数
export interface LiveRoomListParams {
  roomId?: number; // 直播间ID，用于黑名单列表请求
  status?: number; // 直播间状态 -1.删除 0.即将开始 1.直播中 2.回放中  / 举报状态 -1：删除，0：未处理，1：已处理，2：关闭直播间，3：关闭直播间并拉黑主播,
  recommend?: number; // 推荐状态 0.未推荐 1.推荐
  displayFlag?: number; // 展示状态 0.未展示 1.展示中
  search?: string; // 搜索关键字
  page?: number; // 页数
  pageSize?: number; // 每页数量 (默认20)
  globalForbidFlag?: number; // 是否为黑名单 0.不是 1.是黑名单
  adminCreateFlag?: number; // 创建类型  0.用户传教 1.后台创建
  type?: number; // 举报类型 1：违法违禁，2：淫秽色情，3：抽烟喝酒，4：危险行为，5：侮辱谩骂，6：其他
  reason?: string; // 举报原因
  topOrder?: number; // 当前权重
  homeTopOrder?: number; // 首页推荐权重
  homeRecommendFlag?: number; // 是否为首页推荐权重列表  0.不是 1.是首页推荐权重列表
}

// 举报参数
export interface LiveTipParams {
  id: number; // 列表ID
  roomId: number; // 举报直播间ID，用于黑名单列表请求
  roomName: string; // 举报直播间标题
  nickName: string; // 举报直播间主播
  type: number; // 举报类型 1：违法违禁，2：淫秽色情，3：抽烟喝酒，4：危险行为，5：侮辱谩骂，6：其他
  reason: string; // 举报说明
  imgUrl: string; // 举报图片
  status: number; // 举报状态 -1：删除，0：未处理，1：已处理，2：关闭直播间，3：关闭直播间并拉黑主播,
  createTime: number; // 举报创建时间
  updateTime: number; // 举报更新时间
}

// 视频直播机器人随机列表参数
export interface LiveCommentUserListParams {
  nickName?: string; // 用户昵称
  passportId?: string; // 用户ID
}

export interface LiveType {
  name: string;
  topOrder: number;
  status: number;
  id?: number; // 类型 10000.全部 1.其他
  key?: number;
  createTime?: number;
  updateTime?: number;
}

export interface LiveTypeList {
  pageSize: number;
  recordCount: number;
  currentPage: number;
  pageCount: number;
  inforList: LiveType[];
}

export interface GetListParams {
  page: number;
  pageSize: number;
}

// live state
export type LiveModelState = {
  liveTypeList: LiveTypeList;
  liveTipInfo: LiveTipParams;
  liveCommentUserList: LiveCommentUserListParams;
};
export const liveModel: ModelConfig = {
  state: {
    liveTypeList: {
      pageSize: null,
      recordCount: null,
      currentPage: null,
      pageCount: null,
      inforList: []
    },
    // 举报详情
    liveTipInfo: {
      id: 0, // 举报ID
      type: 0, // 举报类型
      status: 0, // 举报状态 -1：删除，0：未处理，1：已处理，2：关闭直播间，3：关闭直播间并拉黑主播,
      roomId: 0, // 举报房间ID
      roomName: "", // 举报房间名称
      reason: "", // 举报原因
      imgUrl: "", // 举报图片
      nickName: "", // 举报人名称
      createTime: 0, // 创建时间
      updateTime: 0 // 更新时间
    },
    liveCommentUserList: [] // 视频直播机器人随机列表
  },
  reducers: {
    liveTypeListSet: (state: LiveModelState, payload: LiveTypeList): void => {
      const listWithKey = [];
      for (const val of payload.inforList) {
        val.key = val.id;
        listWithKey.push(val);
      }
      payload.inforList = listWithKey;
      state.liveTypeList = payload;
    },
    liveTipInfoSet: (state: LiveModelState, payload: LiveTipParams): void => {
      state.liveTipInfo = payload;
    },
    liveCommentUserListSet: (state: LiveModelState, payload: LiveCommentUserListParams): void => {
      state.liveCommentUserList = payload;
    }
  },
  effects: (dispatch: RootDispatch): ModelEffects => ({
    // 请求直播列表
    async getLiveRoomList(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveRoomList,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播详情
    async getLiveRoomDetail(payload: LiveUserlist, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveRoomDetail,
        params: { ...payload }
      });
      return res;
    },

    // 请求新建直播房间
    async getLiveRoomAdd(payload: LiveRoomParams, state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        url: live.liveRoomAdd,
        params: { ...payload }
      });
      return res;
    },

    // 请求新建直播房间
    async getLiveRoomUpdate(payload: LiveRoomParams, state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        url: live.liveRoomUpdate,
        params: { ...payload }
      });
      return res;
    },

    // 请求更改直播间状态，status: -1.删除 1.直播中 2.回放中
    async getLiveRoomStatus(payload: LiveRoomParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveRoomStatus,
        params: { ...payload }
      });
      return res;
    },

    // 请求更改直播间推荐状态，删除直播房间，关闭直播间
    async getLiveRoomRecommend(payload: LiveRoomParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveRoomRecommend,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播间黑名单列表
    async getLiveBlackUserList(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveBlackUserList,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播间黑名单列表
    async getLiveBlackUserDelete(payload: LiveUserlist, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveBlackUserDelete,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播间评论列表
    async getLiveCommentList(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveCommentList,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播间评论删除
    async getLiveCommentDelete(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveCommentDelete,
        params: { ...payload }
      });
      return res;
    },

    // 请求直播间黑名单添加
    async getliveBlackUserAdd(payload: LiveUserlist, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveBlackUserAdd,
        params: { ...payload }
      });
      return res;
    },

    // 直播频道新增
    async liveTypeAdd(payload: LiveType, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveTypeAdd,
        params: { ...payload }
      });
      return res;
    },

    // 更新直播频道
    async liveTypeEdit(payload: LiveType, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveTypeUpdate,
        params: { ...payload }
      });
      return res;
    },

    // 更新直播频道
    async liveTypeOne(payload: { id: number }, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveTypeOne,
        params: { ...payload }
      });
      return res;
    },

    // 获取直播类型列表
    async liveTypeList(payload: GetListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveTypeList,
        params: { ...payload }
      });
      if (res && res.code === 1) {
        dispatch.live.liveTypeListSet(res.obj);
      }
      return res;
    },

    // 获取举报列表
    async liveTipList(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveTipList,
        params: { ...payload }
      });
      return res;
    },

    // 获取举报列表
    async liveTipOne(payload: { id: number }, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveTipOne,
        params: { ...payload }
      });
      if (res && res.code === 1) {
        dispatch.live.liveTipInfoSet(res.obj);
      }
      return res;
    },

    // 处理视频直播举报 更改举报状态 -1：删除，0：未处理，1：已处理，2：关闭直播间，3：关闭直播间并拉黑主播,
    async liveTipStatus(payload: { id: number; status: number }, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveTipStatus,
        params: { ...payload }
      });
      return res;
    },

    // 视频直播机器人随机列表 pageSize: 随机个数 默认500
    async liveCommentUserList(state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: live.liveCommentUserList
      });
      if (res && res.code === 1) {
        dispatch.live.liveCommentUserListSet(res.obj);
      }
      return res;
    },

    // 后台发送直播评论
    async liveCommentSend(
      payload: { roomId: string; content: string; nickName: string; passportId: string },
      state
    ): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveCommentSend,
        params: { ...payload }
      });
      return res;
    },

    // 请求更改直播间自动评论开关
    async getLiveRoomAutoComment(payload: LiveRoomParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveAutoComment,
        params: { ...payload }
      });
      return res;
    },

    // 请求更改直播间自动评论开关
    async getLiveHomeTopOrder(payload: LiveRoomListParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: live.liveHomeTopOrder,
        params: { ...payload }
      });
      return res;
    }
  })
};
