import React, { useState, useEffect } from "react";
import { Layout, Avatar, Drawer, Radio, Dropdown, Menu } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import Cookies from "js-cookie";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, GlobalOutlined, CaretDownFilled } from "@ant-design/icons";
import { useIntl } from "react-intl";
const { Header } = Layout;

interface EProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  localeName?: string;
  changeLocale?: (event: RadioChangeEvent) => void;
}
export default ({ localeName, collapsed, setCollapsed, changeLocale }: EProps): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const { formatMessage: f } = useIntl();
  useEffect(() => {
    if (!Cookies.get("hx_passportId")) window.location.href = "/signin";
  }, []);
  return (
    <Header className="site-layout-header" style={{ padding: 0 }}>
      <div className="header-left">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: () => setCollapsed(!collapsed)
        })}
      </div>
      <div className="header-right">
        {Cookies.get("hx_iconUrl") ? (
          <Avatar className="user-avatar" src={Cookies.get("hx_iconUrl")} />
        ) : (
          <Avatar className="user-avatar" icon={<UserOutlined />} />
        )}
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={(): void => {
                  const cookiesName: string[] = ["token", "nickName", "passportId", "phoneNum"];
                  for (const val of cookiesName) {
                    Cookies.remove(`hx_${val}`);
                  }
                  window.location.href = "/signin";
                }}
              >
                注销
              </Menu.Item>
            </Menu>
          }
        >
          <div className="user-name">
            <span className="name-content">{Cookies.get("hx_nickName")}</span>
            <CaretDownFilled />
            <a href="/signin" className="name-content" style={{ paddingLeft: 20 }}>
              登录
            </a>
          </div>
        </Dropdown>
        {/* 更改语言暂时隐藏 */}
        <div style={{ display: "none" }} className="change-language" onClick={(): void => setVisible(true)}>
          <GlobalOutlined />
        </div>
        <Drawer
          title={f({ id: "changeLocaleTitle" }, { type: localeName ? localeName.toUpperCase() : "" })}
          placement="right"
          closable={false}
          onClose={(): void => setVisible(!visible)}
          visible={visible}
        >
          <Radio.Group value={localeName} onChange={changeLocale}>
            <Radio.Button key="en" value="enus">
              English
            </Radio.Button>
            <Radio.Button key="cn" value="zhcn">
              中文
            </Radio.Button>
          </Radio.Group>
        </Drawer>
      </div>
    </Header>
  );
};
