import React, { useState } from "react";
import { useMount } from "@umijs/hooks";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
import { menus } from "../../routes/index";
import { RootDispatch } from "../../models/store";
import logo from "../../public/images/logo.svg";
import logoIcon from "../../public/images/logo-icon.png";
import { urlMatch } from "../../public/index";
import Cookies from "js-cookie";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface EProps {
  collapsed: boolean;
}
export default ({ collapsed }: EProps): JSX.Element => {
  const [curOpenKeys, setCurOpenKeys] = useState<string[]>([]);
  const [curSelectedKeys, setCurSelectedKeys] = useState<string[]>([]);
  const dispatch: RootDispatch = useDispatch();
  useMount(() => {
    const routeMatch = urlMatch(window.location.pathname);

    /* ----------start---------流量管理可访问性，暂时写死，权限管理做好后删除 */
    const phoneNum = `${Cookies.get("hx_phoneNum")}`;
    if (routeMatch?.path === "/flow/daily" || routeMatch?.path === "/flow/list") {
      if (
        phoneNum !== "17611231175" &&
        phoneNum !== "18201605711" &&
        phoneNum !== "18210234223" &&
        phoneNum !== "18310741355"
      ) {
        window.location.href = "/";
      }
    }
    /* ----------end---------流量管理可访问性，暂时写死，权限管理做好后删除 */

    const curPath = routeMatch?.path.split("/:")[0];
    let breadcrumb: string[] = [];
    for (const item of menus) {
      if (!item.path && item.children) {
        for (const itemChild of item.children) {
          if (itemChild.path === curPath) {
            breadcrumb = [item.title, itemChild.title];
            setCurSelectedKeys([item.key, itemChild.key]);
            setCurOpenKeys([item.key]);
            break;
          }
        }
      } else {
        if (item.path === curPath) {
          breadcrumb = [item.title];
          setCurSelectedKeys([item.key]);
          break;
        }
      }
    }
    // 设置面包屑菜单
    dispatch.common.breadcrumbData(breadcrumb);
  });
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img style={{ height: collapsed ? "28px" : "22px" }} src={collapsed ? logoIcon : logo} alt="火星财经管理后台" />
      </div>
      <Menu selectedKeys={curSelectedKeys} openKeys={curOpenKeys} mode="inline">
        {menus.map((item, index) => {
          if (!item.path && item.children) {
            /* ----------start---------流量管理可访问性，暂时写死，权限管理做好后删除 */
            const phoneNum = `${Cookies.get("hx_phoneNum")}`;
            if (item.key === "flow") {
              if (
                phoneNum !== "17611231175" &&
                phoneNum !== "18201605711" &&
                phoneNum !== "18210234223" &&
                phoneNum !== "18310741355"
              ) {
                return false;
              }
            }
            /* ----------end---------流量管理可访问性，暂时写死，权限管理做好后删除 */
            return (
              <SubMenu
                key={item.key}
                title={
                  <span
                    className="menu-item"
                    onClick={(): void => setCurOpenKeys([item.key === curOpenKeys[0] ? "" : item.key])}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </span>
                }
              >
                {item.children.map((itemChild, indexChild) => {
                  return (
                    <Menu.Item key={itemChild.key}>
                      <a href={itemChild.path}>{itemChild.title}</a>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key}>
                <a href={item.path}>
                  {item.icon}
                  {item.title}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};
