import { RootDispatch } from "./store";
import { ModelConfig, ModelEffects } from "./rematch";
import { auth, communal } from "../public/apis";
import { ajax } from "../public";

export interface DataAdd {
  cmd: "add_task";
  title: string;
  url: string;
  uv: number;
  pv: number;
  startDay: string; // YYYYMMDD 20200501
  endDay: string;
  startHour: number; // 0-23
  endHour: number;
}
export interface DataGet {
  cmd: "query_task";
  state: string; // 0全部，1未开始，2进行中，3自动结束，4手工结束
  keyword: string; // 标题包含关键词
  start: number; // 整数，起始记录序号。默认为0
  num: number; // 整数，返回记录数。默认为0，返回所有
}
export interface DataStop {
  cmd: "stop_task";
  id: number; // 任务id
}
export interface DataDel {
  cmd: "del_task";
  id: number; // 任务id
}
export interface DataQuery {
  cmd: "query_result";
  startDay: string; // YYYYMMDD
  endDay: string; // YYYYMMDD，不能小于 startDay
}
export interface DataItem {
  key: number;
  endDay: string;
  endHour: number;
  id: number;
  progress: number;
  pv: number;
  startDay: string;
  startHour: number;
  stop: number;
  state: string;
  title: string;
  url: string;
  uv: number;
}
export interface SigninParams {
  username: string;
  password: string;
  phoneCode: string;
}
export interface SmsCodeParams {
  phonenum: number;
  countrycode: "86";
  verifcategory: "3";
  token: string;
  authenticate: string;
}

export interface CommonState {
  breadcrumb: string[];
}
export const common: ModelConfig = {
  state: {
    breadcrumb: []
  },
  reducers: {
    breadcrumbData: (state: CommonState, payload: string[]): void => {
      state.breadcrumb = payload;
    }
  },
  effects: (dispatch: RootDispatch): ModelEffects => ({
    async signin(payload: SigninParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: auth.signin,
        params: payload
      });
      return res;
    },
    async getSmsCode(payload: SmsCodeParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: auth.smsCode,
        params: payload
      });
      return res;
    },
    async urlImgUpload(payload, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: communal.urlImgUpload,
        params: { data: JSON.stringify(payload) }
      });
      return res;
    },
    async fileImgUpload(payload, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: communal.fileImgUpload,
        formData: true,
        params: payload
      });
      return res;
    },
    async fileImgUpload2(payload, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: communal.fileImgUpload2,
        formData: true,
        params: payload
      });
      return res;
    },
    async fileLargeUpload({ file, fn }, state): Promise<string | undefined> {
      const totalSize = file.size; // 文件大小
      const blockSize = 1024 * 1024 * 2; // 块大小
      const blockCount = Math.ceil(totalSize / blockSize); // 总块数

      interface ParamsLarge {
        fileName: string;
        blockCount: number;
        currIndex: number;
        uploadId: string;
        uploadFile: null | Blob;
        type: "video";
      }

      const params: ParamsLarge = {
        fileName: file.name,
        blockCount: blockCount,
        type: "video",
        uploadFile: null,
        currIndex: 1,
        uploadId: ""
      };

      const videoUrl: string = await new Promise(function(resolve, reject) {
        const uploading = async (params: ParamsLarge): Promise<{ code: number; obj: string }> => {
          const start = (params.currIndex - 1) * blockSize;
          const end = Math.min(totalSize, start + blockSize);
          const uploadFile = file.slice(start, end);
          params.uploadFile = uploadFile;

          let res = await ajax({
            type: "post",
            url: communal.fileLargeUpload,
            formData: true,
            params: params
          }).catch(function(err) {
            console.log(err);
          });

          // 请求错误或未上传成功，重新上传当前块
          if (!res || (res.code !== 1 && res.code !== 2)) res = await uploading(params);
          // 当前块上传成功，如果当前块currIndex<总块blockCount，继续上传下一块
          if (res.code === 1) {
            params.uploadId = res.obj;
            if (params.currIndex < blockCount) {
              params.currIndex = params.currIndex + 1;
              res = await uploading(params);
            }
          }
          // 全部上传成功，返回视频url
          if (res.code === 2) resolve(res.obj);

          return res;
        };

        uploading(params);
      });

      return videoUrl;
    },
    async dataOptimize(payload: DataAdd | DataGet | DataStop | DataDel | DataQuery, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: communal.dataOptimize,
        params: { ...payload }
      });
      return res;
    }
  })
};
