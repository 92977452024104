import qs from "qs";
import { Base64 } from "js-base64";
import md5 from "blueimp-md5";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { isEqual } from "lodash";
import { match } from "react-router";
import JsEncrypt from "jsencrypt";
import { RouteConfig } from "react-router-config";
import { matchPath } from "react-router-dom";
import { BaseJson, anyType } from "../public/types/public";
import routesConfig from "../routes";

declare global {
  interface Window {
    MozWebSocket: WebSocket;
  }
}

/** @desc 延迟执行 */
export const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

/** @desc 根据当前路由，匹配路由配置，获取参数等 */
type Match = match | null;
export const urlMatch = (url: string): Match => {
  let curRoute: Match = null;
  const hasKey = (arr: RouteConfig[]): void => {
    for (const val of arr) {
      if (val.path) {
        const params: RouteConfig = { path: val.path };
        params.path = val.path;
        if (val.exact) params.exact = val.exact;
        if (val.strict) params.strict = val.strict;
        curRoute = matchPath(url, params);
      }

      const isObj = Object.prototype.toString.call(curRoute) === "[object Object]";
      if (isObj) return;
      if (val.routes && !isObj) {
        hasKey(val.routes);
      }
    }
  };
  hasKey(routesConfig);
  return curRoute;
};

/**
 * @desc 密码加密
 * @returns {string}
 * @Params {password}
 * @method encodePsd(password)
 */
const publicKey =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAg3mRptcLRCaT2BiEH8ZI1cTWToCZyX/XUZHZ902Bhk4fvDZchHsI3JKQHx7iS4RrYyI8G94eIdh1yYnd2fuwylmG1+FGcZeV5N7lex57Uih9Sm4OOz9kJq3vRZLX+M5vMEb+sAgWICJByKxOlGNMZhAbnhYpu8PaZzJGFFwt/cjXIDY7RX7YIP3gRTc4sDUfjv6DkjGYyqVmjRQgggSFtjR0euaelCZxzOPF5xShNubXX5b9xJuBAz+NhWCJ8zeEchOZkRAwujRPC1ebmfsTumecopjyaRUAb1csvscgG+frluDfs8zSUKDutkLNOe8utD2/KkHnQfUxucaV1XbPOQIDAQAB";
const randomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
export const encodePsd = (password: string): string => {
  const encrypt = new JsEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(
    JSON.stringify({
      content: password.toString(),
      nonce: randomNumber(0, 1000000).toString(),
      timestamp: (Date.parse(new Date().toDateString()) / 1000).toString()
    })
  );
};

/** @desc 基于axios，根据业务需要与使用习惯封装ajax请求 */
export const ajaxSignature = (): string => {
  const platform = "pc";
  const appSecret = "Fbz]OdjAyhpqOIKA";
  const nonceArr = "abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ1234567890";
  const timestamp = new Date().getTime();
  let nonce = "";
  for (let i = 0; i < 6; i++) {
    const j = Math.round(Math.random() * nonceArr.length);
    nonce += nonceArr[j];
  }
  const sig = md5(`platform=${platform}&timestamp=${timestamp}&nonce=${nonce}&${appSecret}`);
  return Base64.encode(
    JSON.stringify({
      platform: platform,
      nonce: nonce,
      timestamp: timestamp,
      sig: sig
    })
  );
};
const createLoading = (noLoading?: boolean): void => {
  let ajaxLoadingStr = `<div className="ajax-loading"><div className="component-loading"><div className="sk-spinner sk-spinner-pulse"></div></div></div>`;
  if (noLoading) ajaxLoadingStr = '<div id="ajaxLoading"></div>';
  if (!document.getElementById("ajaxLoading")) document.body.insertAdjacentHTML("beforeend", ajaxLoadingStr);
};
const removeLoading = (): void => {
  if (document.getElementById("ajaxLoading")) {
    const $ajaxLoading = document.getElementById("ajaxLoading") as HTMLElement;
    const $parent = $ajaxLoading.parentNode as Node;
    $parent.removeChild($ajaxLoading);
  }
};
// const apiHost = process.env.NODE_ENV === "production" ? "https://api.huoxing24.com" : "";
const apiHost = "";
type AjaxReqType = "get" | "post" | "postpure";
export interface AjaxReq<P> {
  type: AjaxReqType;
  url: string;
  baseUrl?: string;
  params?: P;
  contentType?: string;
  formData?: boolean;
  noLoading?: boolean;
  noLog?: number[];
  userDefined?: BaseJson;
}
export function ajax<P extends { [K in keyof P]: anyType } = {}>({
  type,
  url,
  params,
  baseUrl = apiHost,
  formData,
  contentType,
  userDefined,
  noLog,
  noLoading
}: AjaxReq<P>): Promise<AxiosResponse["data"]> {
  createLoading(noLoading);
  const config: AxiosRequestConfig = {
    url: url,
    baseURL: baseUrl
  };
  if (type === "post") {
    config.method = "post";
    config.data = qs.stringify(params);
  } else if (type === "get") {
    config.method = "get";
    config.params = params;
  } else if (type === "postpure") {
    config.method = "post";
    config.data = params;
  }

  // 文件上传时使用formData数据
  if (type === "post" && formData) {
    const data = new FormData();
    for (const key in params) {
      data.append(key, params[key] as string);
    }
    config.data = data;
  }

  // 设置请求头headers的contentType
  if (contentType) {
    config.headers = {
      "Content-Type": contentType
    };
  }

  // 用户自定义请求头参数
  if (userDefined) {
    config.headers = Object.assign(config.headers ? config.headers : {}, userDefined);
  }

  // 财经接口请求头签名参数
  config.headers = Object.assign(config.headers ? config.headers : {}, {
    "Sign-Param": ajaxSignature()
  });

  return new Promise(function(resolve, reject) {
    axios(config)
      .then(function(res) {
        // 自定义是否上报日志,返回的code包含在数组中，或者为空数组时。不需要上报日志
        let noLogTrue = false;
        if (noLog && Array.isArray(noLog)) {
          if (noLog.length === 0) {
            noLogTrue = true;
          } else {
            for (const val of noLog) {
              if (res.data && res.data.code && res.data.code === val) {
                noLogTrue = true;
                break;
              }
            }
          }
        }

        if (res.data && res.data.code && res.data.code !== 1 && !noLogTrue) {
          const resCode = res.data.code;
          const resMsg = res.data.msg ? res.data.msg : "api code is not 1";
          if (resCode === -4) {
            // 登录失效时清除登录信息并跳转到登录页面
            // window.location.href = "/signin";
            alert("登录过期，请重新登录");
            window.location.href = "/signin";
            return;
          }
          if (url.indexOf("/logger") === -1) {
            logReport({
              level: "error",
              message: "client-api-msg",
              httpCode: 200,
              url: url,
              params: isJson(params as BaseJson) ? JSON.stringify(params) : params,
              resCode: resCode,
              resMsg: resMsg,
              framework: true
            });
          }
        }

        if (res.data) {
          resolve(res.data);
        } else {
          resolve({
            code: 0,
            msg: "response has no data property"
          });
        }
        removeLoading();
      })
      .catch(function(err) {
        const logParams = {
          level: "error",
          message: "client-api-msg",
          url: url,
          params: isJson(params as BaseJson) ? JSON.stringify(params) : params
        };
        if (err.response) {
          // 请求已发出，但服务器响应的状态码不在 2xx 范围内
          logReport({
            ...logParams,
            httpCode: err.response.status,
            data: err.response.data,
            headers: err.response.headers,
            errConfig: err.config,
            framework: true
          } as LogReportParams);
        } else {
          // Something happened in setting up the request that triggered an Error
          logReport({
            ...logParams,
            httpCode: 500,
            errMsg: err.message,
            errConfig: err.config,
            framework: true
          } as LogReportParams);
        }
        removeLoading();
        reject(err);
      });
  });
}

/** @desc
 * 前端日志上报到node服务: 框架自动打印的params中会有framework:true参数(ajax)，会自动上传localstorage和sessionstorage
 **/
interface LogReportParams {
  message: string;
  level?: "error" | "warn" | "info" | "http" | "verbose" | "debug" | "silly";
  framework?: boolean;
  [key: string]: anyType;
}
let logArr: Array<LogReportParams> = [];
let logPreTime = new Date().getTime(); // 1秒内logArr中不存在的errObj才上报。同一页面同一信息1秒内不再上报
export const logReport = (params: LogReportParams): void => {
  const logAjax = (): void => {
    const logObj = {} as {
      localStorage: string;
      sessionStorage: string;
    };
    // localStorage转为string类型防止log格式过乱
    if (window.localStorage && params.framework) {
      const localTmp = {} as BaseJson;
      for (const key of Object.keys(window.localStorage)) {
        if (key.indexOf("Hm_lvt_") === -1 && key.indexOf("Hm_lpvt_") === -1 && key.indexOf("m_unsent_") === -1) {
          // 在此排除不需要的信息
          localTmp[key] = localStorage.getItem(key);
        }
      }
      logObj.localStorage = JSON.stringify(localTmp);
    }

    // sessionStorage转为string类型防止log格式过乱
    if (window.sessionStorage && params.framework) {
      const localTmp = {} as BaseJson;
      for (const key of Object.keys(window.sessionStorage)) {
        if (key.indexOf("Hm_lvt_") === -1 && key.indexOf("Hm_lpvt_") === -1 && key.indexOf("m_unsent_") === -1) {
          // 在此排除不需要的信息
          localTmp[key] = sessionStorage.getItem(key);
        }
      }
      logObj.sessionStorage = JSON.stringify(localTmp);
    }

    const paramsObj = { ...params, ...logObj } as LogReportParams;
    if (
      // logger自身报错不上报
      (paramsObj.url && paramsObj.url.indexOf("/logger") === -1) ||
      !paramsObj.url
    ) {
      ajax({
        type: "post",
        url: "/logger",
        noLoading: true,
        params: paramsObj
      });
    }
  };

  const logCurTime = new Date().getTime();
  if (logCurTime - logPreTime >= 1000 && logArr.length !== 0) {
    logArr = [];
    logArr.push(params);
    logPreTime = logCurTime;
    logAjax();
  } else {
    const hasParams = (): boolean => {
      for (const val of logArr) {
        if (isEqual(val, params)) {
          return true;
        }
      }
      return false;
    };
    if (logArr.length !== 0 && hasParams()) return;
    logArr.push(params);
    logAjax();
  }
};

/**
 * @desc 判断Json字符串是否为正确的Json格式
 */
export const isJson = (obj: string | BaseJson): boolean => {
  if (typeof obj === "string") {
    try {
      const objFormat = JSON.parse(obj);
      return typeof objFormat === "object" && objFormat;
    } catch (e) {
      return false;
    }
  } else {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }
};

/**
 * @desc 去除字符串两边空格
 * @returns {string}
 * @Params {string} string
 * @method trim(string)
 */
export const trim = (string: string): string => {
  return string.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * @desc 判断是否是正确的手机号
 * @returns {Boolean}
 * @Params {phoneNumber}
 * @method isPhone()
 */
export const isPhone = (phoneNumber: string): boolean => {
  const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return myreg.test(`${phoneNumber}`) || false;
};

/**
 * @desc 格式化时间，将 Date 转化为指定格式的String
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @returns {string}
 * @Params {date, fmt}
 * @method formatTime(time, "yyyy-MM-dd hh:mm:ss") ==> 2006-07-02 08:09:04.423
 *         formatTime(time, "yyyy.M.d h:m:s")      ==> 2006.7.2 8:9:4.18
 */
export const formatTime = (date: string, fmt: string): string => {
  const This = new Date(date);
  interface DateFormat {
    [index: string]: number;
  }
  const o: DateFormat = {
    "M+": This.getMonth() + 1, // 月份
    "d+": This.getDate(), // 日
    "h+": This.getHours(), // 小时
    "m+": This.getMinutes(), // 分
    "s+": This.getSeconds(), // 秒
    "q+": Math.floor((This.getMonth() + 3) / 3), // 季度
    S: This.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (This.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      const replaceValue = (RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)).toString();
      fmt = fmt.replace(RegExp.$1, replaceValue);
    }
  }
  return fmt;
};

/**
 * @desc 生成uuid 全局唯一标识符（GUID，Globally Unique Identifier）也称作 UUID(Universally Unique IDentifier)
 * @method uuid()
 * */
export const uuid = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * @desc 滚动条的滚动位置
 * @returns {top,  left}
 * @method scrollOffset()
 */
export const scrollOffset = (): { left: number; top: number } => {
  if (window.pageXOffset) {
    return {
      left: window.pageXOffset,
      top: window.pageYOffset
    };
  } else {
    const el = document.scrollingElement || document.documentElement;
    return {
      left: el.scrollLeft,
      top: el.scrollTop
    };
  }
};

/**
 * @desc 获取元素相对于文档的绝对位置和高宽/getBoundingClientRect元素相对于可视区域的位置与高宽
 * @returns {top,  left}
 * @method elementOffset()
 */
export const elementOffset = (
  ele: HTMLElement
): {
  top: number;
  left: number;
  bottom: number;
  right: number;
  height: number;
  width: number;
} => {
  return {
    top: ele.getBoundingClientRect().top + scrollOffset().top,
    left: ele.getBoundingClientRect().left + scrollOffset().left,
    bottom: ele.getBoundingClientRect().bottom + scrollOffset().top,
    right: ele.getBoundingClientRect().right + scrollOffset().left,
    height: ele.getBoundingClientRect().height,
    width: ele.getBoundingClientRect().width
  };
};

/**
 * @desc 获取鼠标相对于文档的坐标/离可视区域的用clientX+clientY
 * @returns {top,  left}
 * @method mouseOffset()
 */
export const mouseOffset = (event: MouseEvent): { x: number; y: number } => {
  const e = event || window.event;
  const scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
  const scrollY = document.documentElement.scrollTop || document.body.scrollTop;
  return {
    x: e.pageX || e.clientX + scrollX,
    y: e.pageY || e.clientY + scrollY
  };
};

export interface ArgsParams {
  host: string;
  url: string;
  params: anyType;
  binaryType: BinaryType;
  https: boolean;
  success: (event: Event) => void;
  message: (event: Event) => void;
  close: (event: Event) => void;
  error: (event: Event) => void;
}

/**
 * @desc websocket链接
 * @params {args} args = {
 *    host 如果不是默认域名添加此参数,
 *    url 接口路由,
 *    params 发送的参数,
 *    binaryType 指定接收数据类型: blob,arraybuffer，
 *    https true支持https传此参数，
 *    success 链接成功，返回event,
 *    message 接收消息，返回event,
 *    close 链接关闭时，返回此event,
 *    error 链接错误，返回此event,
 * }
 * @returns {ws} ws: 此websocket对象，用于后续其它操作。
 * ws.send()
 * ws.close()
 * ws.bufferedAmount 0发送完毕，else发送中
 * ws.readyState CONNECTING：值为0，表示正在连接。OPEN：值为1，表示连接成功，可以通信了。CLOSING：值为2，表示连接正在关闭。CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
 * @method websocket(args)
 * */
export const websocket = (args: ArgsParams): Promise<anyType> =>
  new Promise(function(resolve, reject) {
    try {
      const { host, url, params, binaryType, https, success, message, close, error } = args;

      const wssUrl = `${https || process.env.NODE_ENV === "production" ? "wss" : "ws"}://${(host ||
        (apiHost.indexOf("http") > -1 ? apiHost.split("://")[1] : apiHost)) + (url || "")}`;

      let ws: WebSocket;
      let lockReconnect = false; // 避免重复连接
      if (window.WebSocket || window.MozWebSocket) {
        // onopen, onmessage心跳检测
        const heartCheck = {
          timeout: 60000,
          timeoutObj: null,
          serverTimeoutObj: null,
          reset: (): anyType => {
            clearTimeout(this.timeoutObj);
            clearTimeout(this.serverTimeoutObj);
            return this;
          },
          start: (): void => {
            this.timeoutObj = setTimeout(function() {
              ws.send("ping");
              // 如果再timeout时间内没有重置，认为后端主动断开链接，则进行重连
              this.serverTimeoutObj = setTimeout(function() {
                // onclose会执行reconnect，执行ws.close()就行了
                // 如果直接执行reconnect 会触发onclose导致重连两次
                ws.close();
              }, this.timeout);
            }, this.timeout);
          }
        };

        // onerror, onclose时重新连接
        const reconnect = (): void => {
          if (lockReconnect) return;
          lockReconnect = true;
          setTimeout(function() {
            createWebSocket();
            lockReconnect = false;
          }, 2000);
        };

        const createWebSocket = (): void => {
          const BrowserWebSocket = window.WebSocket || window.MozWebSocket;
          // ws = new BrowserWebSocket(wssUrl, 'protocol')
          ws = new BrowserWebSocket(wssUrl);
          if (binaryType) ws.binaryType = binaryType;

          ws.onopen = (event): anyType | null => {
            heartCheck.reset().start();
            ws.send(
              JSON.stringify({
                action: "auth",
                args: [ajaxSignature()]
              })
            );
            if (params) ws.send(JSON.stringify(params));
            if (success) success(event);

            resolve(ws);
          };
          ws.onmessage = (event): anyType | null => {
            heartCheck.reset().start();
            if (event.data === "pong") return;
            if (message) message(event);
          };
          ws.onclose = (event): anyType | null => {
            reconnect();
            if (close) close(event);
          };
          ws.onerror = (err): anyType | null => {
            reconnect();
            if (error) error(err);
          };

          // 页面关闭或刷新前关闭ws链接
          window.onbeforeunload = (): (anyType & anyType) | null => {
            ws.close();
          };
        };

        createWebSocket();
      } else {
        const tips = "当前浏览器不支持WebSocket";
        console.error(tips);
        reject(new Error(tips));
      }
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });

/** @desc 图片的 dataurl 转 blob*/
export const dataURLtoBlob = (dataurl: string): Blob => {
  const arr = dataurl.split(",");
  const mimeTemp = arr[0].match(/:(.*?);/);
  const mime = mimeTemp !== null ? mimeTemp[1] : "";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

/**
 * @desc 图片url地址转成Base64接口（请求服务器接口，解决跨域问题）
 * @params {imgUrl} 图片url地址
 * @returns 返回Base64
 * */
export const getBase64 = async (imgUrl: string): Promise<string> => {
  const res = await ajax({
    type: "post",
    url: "/imgToBase64",
    noLoading: true,
    params: { imgUrl: imgUrl }
  });
  if (res.code === 1) {
    return res.obj;
  } else {
    return "";
  }
};
