import { RootDispatch } from "./store";
import { ModelConfig, ModelEffects } from "./rematch";
import { feature } from "../public/apis";
import { ajax } from "../public";

// 专题列表每项类型
export interface TopicListItem {
  id: string; // id
  createTime: number; // 创建时间
  description: string; // 说明
  keyword: string;
  mBackImage: string;
  mImgSrc: string;
  newSmallPcImgSrc: string;
  pcBackImage: string;
  pcImgSrc: string;
  recommend: number; // 不传 或者 0 全部列表  1.推荐列表
  newsCount: number; // 内容数量
  setTop: number; // 权重
  status: number; // 1.普通 2.推荐
  tags: string;
  topicName: string;
  type: number; // 4.专题
  typeLink: string;
  titleDisplayFlag: number; // 0.不展示标题，1.展示标题
}
// 专题内容列表每项类型
export interface TopicQuerycontentItemParams {
  id: string; // id
  publishTime?: number; // 发布时间
  title: string; // 标题
  topicId?: string; // 所属专题ID
  contentType: number; // 类型 0.文章 1.视频
  url: string; // 跳转链接
  synopsis?: string; // 备注 暂无
  topOrder: number; // 权重
  coverPic?: string; // 图片
}

// 请求专题列表参数类型
export interface TopicListParams {
  search: string;
  type: 4; // 4.专题
  status: string; // 1.普通 2.推荐
  recommend: string; // 不传 或者 0 全部列表  1.推荐列表
  pageSize: number;
  currentPage: number;
}
// 请求列表操作
export interface TopicStatusParams {
  id: string;
  status: number; // 0.删除 1.撤销推荐 2.更改权重
  type?: number; // 更改的类型 4.专题
  setTop?: number; // 更改权重数值
}
// 请求专题详情
export interface TopicQuerytopicParams {
  id: string;
}
// 请求专题内容
export interface TopicQuerycontentParams {
  id: string;
  pageSize: number;
  currentPage: number;
}
// 请求专题内容添加
export interface TopicAddcontentParams {
  contentList: TopicQuerycontentItemParams[];
  topic: {
    id: string;
  };
}
// 请求专题新增
export interface TopicAddParams {
  contentList: TopicQuerycontentItemParams[];
  topic: TopicListItem;
}
// 请求全部推荐权重
export interface TopicRecommendnumParams {
  type: number; // 4.专题
}

export const featureModel: ModelConfig = {
  state: {},
  reducers: {},
  effects: (dispatch: RootDispatch): ModelEffects => ({
    // 请求专题列表
    async getTopicList(payload: TopicListParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: feature.topicList,
        params: { ...payload }
      });
      return res;
    },

    // 专题列表操作 status 0：删除， 1：撤销首页展示 撤回推荐， 2：修改推荐位权重
    async getTopicStatus(payload: TopicStatusParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: feature.topicStatus,
        params: { ...payload }
      });
      return res;
    },

    // 专题详细数据
    async getTopicQuerytopic(payload: TopicQuerytopicParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: feature.topicQuerytopic,
        params: { ...payload }
      });
      return res;
    },

    // 专题内容列表
    async getTopicQuerycontent(payload: TopicQuerycontentParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: feature.topicQuerycontent,
        params: { ...payload }
      });
      return res;
    },

    // 专题内容添加
    async getTopicAddcontent(payload: TopicAddcontentParams, state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        url: feature.topicAddcontent,
        params: { ...payload }
      });
      return res;
    },

    // 专题内容修改
    async getTopicUpdatecontent(payload: TopicQuerycontentItemParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: feature.topicUpdatecontent,
        params: { ...payload }
      });
      return res;
    },

    // 专题内容修改
    async getTopicDelcontent(payload: TopicQuerycontentItemParams, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: feature.topicDelcontent,
        params: { ...payload }
      });
      return res;
    },

    // 专题更新
    async getTopicUpdate(payload: TopicListItem, state): Promise<void> {
      const res = await ajax({
        type: "post",
        url: feature.topicUpdate,
        params: { ...payload }
      });
      return res;
    },

    // 专题新增
    async getTopicAdd(payload: TopicAddParams, state): Promise<void> {
      const res = await ajax({
        type: "postpure",
        url: feature.topicAdd,
        params: { ...payload }
      });
      return res;
    },

    // 获取全部推荐权重 type 4.专题
    async getTopicRecommendnum(payload: TopicRecommendnumParams, state): Promise<void> {
      const res = await ajax({
        type: "get",
        url: feature.topicRecommendnum,
        params: { ...payload }
      });
      return res;
    }
  })
};
