import React, { lazy } from "react";
import { RouteConfig } from "react-router-config";
import Layout from "../components/Layout";

/** @desc
 * ----------菜单配置----------
 * 跳转为<a>标签刷新页面跳转, 别用<Link>路由跳转，否则不能设置当前(展开，选择)路由, 因为在Menu的useMount中设置的
 * key: selectKey当前选择项, openKey当前展开项
 * path: routes路由配置项，除”/:“参数的路径
 */
import {
  BarChartOutlined,
  HomeOutlined,
  SnippetsOutlined,
  VideoCameraOutlined,
  LineChartOutlined,
  MergeCellsOutlined
} from "@ant-design/icons";
export const menus = [
  {
    key: "home",
    path: "/",
    title: "主页",
    icon: <HomeOutlined />
  },
  {
    key: "flow",
    title: "流量优化",
    icon: <BarChartOutlined />,
    children: [
      {
        key: "flowList",
        path: "/flow/list",
        title: "优化列表"
      },
      {
        key: "flowDaily",
        path: "/flow/daily",
        title: "每日数据"
      }
    ]
  },
  {
    key: "column",
    title: "专题管理",
    icon: <SnippetsOutlined />,
    children: [
      {
        key: "columnList",
        path: "/column/list",
        title: "专题列表"
      },
      {
        key: "columnTop",
        path: "/column/top",
        title: "推荐专题"
      }
    ]
  },
  {
    key: "live",
    title: "直播管理",
    icon: <VideoCameraOutlined />,
    children: [
      {
        key: "liveList",
        path: "/live/list",
        title: "火星直播间列表"
      },
      {
        key: "liveUserList",
        path: "/live/user/list",
        title: "用户直播间列表"
      },
      {
        key: "liveRecommendList",
        path: "/live/recommend/list",
        title: "首页推荐管理"
      },
      {
        key: "liveBlackList",
        path: "/live/blacklist",
        title: "黑名单管理"
      },
      {
        key: "liveTypeList",
        path: "/live/typelist",
        title: "分类管理"
      },
      {
        key: "liveTipList",
        path: "/live/tip/list",
        title: "举报管理"
      }
      // {
      //   key: "liveChat",
      //   path: "/live/chat",
      //   title: "聊天管理"
      // },
      // {
      //   key: "liveForbidUser",
      //   path: "/live/forbid",
      //   title: "禁言用户管理"
      // }
    ]
  },
  {
    key: "quotation",
    title: "行情管理",
    icon: <LineChartOutlined />,
    children: [
      {
        key: "quotationWarningList",
        path: "/quotation/warning/list",
        title: "行情预警"
      }
    ]
  },
  {
    key: "project",
    title: "项目库管理",
    icon: <MergeCellsOutlined />,
    children: [
      {
        key: "projectList",
        path: "/project/list",
        title: "项目列表"
      },
      {
        key: "projectType",
        path: "/project/typelist",
        title: "分类管理"
      },
      {
        key: "projectBanner",
        path: "/project/banner",
        title: "banner管理"
      }
    ]
  }
];

/** @desc
 * ----------路由配置----------
 * exact 精准的 是否匹配子路由
 * strict 严格的 是否匹配斜杠”/“
 */
const NoMatch = lazy(() => import("../pages/NoMatch"));
const Home = lazy(() => import("../pages/Home"));
const Signin = lazy(() => import("../pages/Signin"));
const FlowList = lazy(() => import("../pages/flow/FlowList"));
const FlowDaily = lazy(() => import("../pages/flow/FlowDaily"));
const ColumnList = lazy(() => import("../pages/column/ColumnList"));
const ColumnEdit = lazy(() => import("../pages/column/ColumnEdit"));
const LiveList = lazy(() => import("../pages/Live/LiveList"));
const ProjectList = lazy(() => import("../pages/Project/ProjectList"));
const projectTypeList = lazy(() => import("../pages/Project/projectTypeList"));
const ProjectAddEdit = lazy(() => import("../pages/Project/ProjectAddEdit"));
const LiveChat = lazy(() => import("../pages/Live/LiveChat"));
const LiveAddEdit = lazy(() => import("../pages/Live/LiveAddEdit"));
const LiveForbidUser = lazy(() => import("../pages/Live/LiveForbidUser"));
const LiveBlackList = lazy(() => import("../pages/Live/LiveBlackList"));
const LiveTypeList = lazy(() => import("../pages/Live/LiveTypeList"));
const LiveUserList = lazy(() => import("../pages/Live/LiveUserList"));
const LiveUserEdit = lazy(() => import("../pages/Live/LiveUserEdit"));
const LiveTipList = lazy(() => import("../pages/Live/LiveTipList"));
const LiveTipEdit = lazy(() => import("../pages/Live/LiveTipEdit"));
const LiveRecommendList = lazy(() => import("../pages/Live/LiveRecommendList"));
const QuotationWarningList = lazy(() => import("../pages/Quotation/QuotationWarningList"));
const QuotationWarningEdit = lazy(() => import("../pages/Quotation/QuotationWarningEdit"));
const routes: RouteConfig[] = [
  {
    path: "/signin",
    component: Signin
  },
  {
    component: Layout,
    routes: [
      {
        path: ["", "/"],
        component: Home,
        exact: true,
        strict: true
      },
      {
        path: "/flow/daily",
        component: FlowDaily
      },
      {
        path: ["/flow/list", "/flow/list/:id"],
        exact: true,
        strict: true,
        component: FlowList
      },
      {
        path: ["/column/list", "/column/top"],
        exact: true,
        strict: true,
        component: ColumnList
      },
      {
        path: ["/column/edit", "/column/edit/:id"],
        exact: true,
        strict: true,
        component: ColumnEdit
      },
      {
        path: "/live/list",
        component: LiveList
      },
      {
        path: "/project/list",
        component: ProjectList
      },
      {
        path: "/project/typelist",
        component: projectTypeList
      },
      {
        path: ["/project/edit", "/project/edit/:id"],
        exact: true,
        strict: true,
        component: ProjectAddEdit
      },
      {
        path: ["/live/edit", "/live/edit/:id"],
        exact: true,
        strict: true,
        component: LiveAddEdit
      },
      {
        path: "/live/chat/:id/:status",
        component: LiveChat
      },
      {
        path: "/live/forbid/:id",
        component: LiveForbidUser
      },
      {
        path: "/live/blacklist",
        component: LiveBlackList
      },
      {
        path: "/live/typelist",
        component: LiveTypeList
      },
      {
        path: "/live/user/list",
        component: LiveUserList
      },
      {
        path: "/live/user/edit/:id",
        component: LiveUserEdit
      },
      {
        path: "/live/tip/list",
        component: LiveTipList
      },
      {
        path: "/live/tip/edit/:id",
        component: LiveTipEdit
      },
      {
        path: "/live/recommend/list",
        component: LiveRecommendList
      },
      {
        path: "/quotation/warning/list",
        component: QuotationWarningList
      },
      {
        path: "/quotation/warning/edit",
        component: QuotationWarningEdit
      },
      {
        path: "*",
        component: NoMatch
      }
    ]
  }
];
export default routes;
